<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Add Sub Category">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">

                <b-row>
                  <b-col md="12" >
                    
                    <b-form-group
                      label="Supplier"
                      class="required"
    
                    >
                      <!-- <b-form-select v-model="form.supplier" @change="changeSupplier()">
    
                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                        
                        <b-form-select-option :value="supplier._id" v-for="supplier in suppliers" :key="supplier._id">{{supplier.name | capitalize}}</b-form-select-option>
                        
                        
                      </b-form-select> -->

                      <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suppliers"
                          label="name"
                          class="w-100"
                          placeholder="Supplier"
                          @input="changeSupplier()"
                          v-model="form.supplier"
                          :clearable="false"
                        >
                        <template #selected-option="{ name }">
                            <span v-if="name && name.length < 23">{{ name }}</span>
                            <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                          </template>
                        </v-select>

                        
                    </b-form-group>
                  </b-col>
    
                </b-row>

                <b-row class="mb-1">
                    <b-col md="12" >
                    
                      <b-form-group
                        label="Category"
                        class="required"
    
                      >
                        <!-- <b-form-select v-model="form.category">
    
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                        
                          <b-form-select-option :value="category._id" v-for="category in categories" :key="category._id">{{ category.name | capitalize }}</b-form-select-option>
                        
                        
                        </b-form-select> -->

                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="categories"
                            label="name"
                            class="w-100"
                            placeholder="Category"
                            
                            v-model="form.category"
                            :clearable="false"
                          >
                          <template #selected-option="{ name }">
                            <span v-if="name && name.length < 23">{{ name }}</span>
                            <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                          </template>
                        </v-select>

                        
                        
                      </b-form-group>
                    </b-col>
    
                  </b-row>

                <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                  <table class="table">
                      <thead>
                          <tr>
                              <th scope="col" style="width: 95%">Sub Category Name <span class="text-danger">*</span></th>
                              <th scope="col" style="width: 5%"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(item,index) in form.names" :key="index">
                              <td>
                                <b-form-input placeholder="3 to 50 characters" v-model="item.name" autocomplete="off" />
                              </td>
                              <td class="text-center">
                                <b-button variant="danger" @click="removeRow(index);">-</b-button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>

                <b-row class="mt-1 mb-2">
                  <b-col md="12" class="text-center">
                      <hr>
                      <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                  </b-col>
                </b-row>
                
               
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select'

    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect
      },
      directives: {
        Ripple,
      },
      data() {
        return {
  
          error_message:null,
          showDismissibleAlert:false,
    
          form :{
            
            supplier:null, 
            category:null,
            names:[{
              name:''
            }],           
          },
          
          suppliers:[],
          categories:[]
          
        }
      },
      methods : {

        addRow(){
          this.form.names.push({
              name : ''
          })
        },

        removeRow(index) {
          if(this.form.names.length > 1){
              this.form.names.splice(index, 1);
          }
        },
        updateSite(){
          this.form.supplier = null;
          // this.form.names = [{name:''}];
          this.getSuppliers();
          this.form.category = null;
          this.getCategories();

        },
        changeSupplier(){
          this.form.category = null;
          this.getCategories();
        },
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/add-supplier-sub-category'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Added Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name: 'supplier-sub-category', params: { supplier_id: this.$route.params.supplier_id, category_id: this.$route.params.category_id } })
                    });
                    
                }
            });
          
        },
    
        
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'suppliers'},
            text: 'Suppliers Info',
          },{
            to:{name:'supplier-category',params:{supplier_id:this.$route.params.supplier_id}},
            text: 'Categories',
          }, {
            to: { name: 'supplier-sub-category', params: { supplier_id: this.$route.params.supplier_id , category_id :this.$route.params.category_id } },
            text: 'Sub Categories',
          },{
            to:null,
            text: 'Add Sub Category',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name: 'supplier-sub-category', params: { supplier_id: this.$route.params.supplier_id, category_id: this.$route.params.category_id } })
        },
        getSuppliers(){
          return this.$store.dispatch(POST_API, {
            data:{
                  role:this.$store.getters.currentUser.role,
                  
            },
            api: '/api/all-suppliers'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
              } else {
                  this.suppliers = this.$store.getters.getResults.data;
                  return this.suppliers;
              }
          });
        },
        getCategories() {
          return this.$store.dispatch(POST_API, {
            data: {
              role: this.$store.getters.currentUser.role,
              
              supplier: this.form.supplier == '' ? null : this.form.supplier,
              category:this.form.category == ''? null : this.form.category
            },
            api: '/api/all-suppliers-categories'
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
            } else {
              this.categories = this.$store.getters.getResults.data;
              return this.categories;
            }
          });
        }, 
        async setParamsValue(){
          
          await this.getSuppliers();


          if (this.$route.params.supplier_id) {
            if (this.$route.params.supplier_id != 'all-suppliers') {
              //this.form.supplier = this.$route.params.supplier_id;

              this.form.supplier = this.suppliers.find((item) => { return this.$route.params.supplier_id == item._id });


              if (this.$route.params.category_id) {

                await this.getCategories();


                if (this.$route.params.category_id != 'all-categories') {
                  //this.form.category = this.$route.params.category_id;
                  this.form.category = this.categories.find((item) => { return this.$route.params.category_id == item._id });
                }
              }

            }
          }

          

        }       
    
      },
      mounted(){
        
        this.setParamsValue();
      }
    }
    </script>

    <style lang="scss">
        @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    